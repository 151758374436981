body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*{
scroll-behavior: smooth ;
}
#hknws::before{
  opacity: 0.5;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#dse tr, #dse td{
border:1px solid #ffcb05;
border-collapse: collapse;
}
::-webkit-scrollbar {
  width: 8px !important;
}

::-webkit-scrollbar-track {
  background-color: #ffffff00;
  
}

::-webkit-scrollbar-thumb {
  background: #ffcb05; 
  border-radius: 10px;
}
#navbar{
  transition: 1s;
}

.hoverBitUp{
  transition: 0.5s;
  margin-bottom: 0px;
}
.hoverBitUp:hover{
transition: 0.5s;
transform: scale(1.1);
margin-bottom: 20px ;
}
.hoverDown{
  transition: 0.5s;
}
.hoverDown:hover{
  transition: 0.5s;
  margin-top: 40px;
  transform: scale(0.8);
  border-radius: 50%;
  background-color:#ffffff;
}