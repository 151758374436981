.btn-transparent{
  background-color: #ffffff00;
  border: none;
  transition: 0.1s;
  margin: 0px;

}
body{
  scroll-behavior: smooth;
}
.childSnap {
  scroll-snap-align: center;
  scroll-padding: 0;
  scroll-snap-type: mandatory;
}
.pointer{
  cursor: pointer;
}
.hoverBorder small{
  transition: 0.6s;
  cursor: pointer;
}
.hoverBorder{
  transition: 0.6s;
}
.hoverBorder:hover{
  transition: 0.3s;
  border-bottom: #ffcb05 15px solid;
}
.btn-transparent:hover{
  transition: 0.1s;
  margin: 2px;  
  animation: App-logo-spin infinite 1s ;
}
td, tr, th{
  padding: 10px;
}
.responsive64{
  font-size: 64px;
}
.managementBorder{
  transition: 0.5s;
  padding: 20px;
  border-bottom: 6px solid #ffcb05 !important;
  border-right: 6px solid #ffcb05 !important;
  border-top: 0px solid #ffcb05 !important;
  border-left: 0px solid #ffcb05 !important;
}
.managementBorder:hover{
  transition: 0.5s;
  border-bottom: 0px solid #ffcb05 !important;
  border-right: 0px solid #ffcb05 !important;
  border-top: 6px solid #ffcb05 !important;
  border-left: 6px solid #ffcb05 !important;
}

.marqueeCH {
  height: 50px;	
  overflow: hidden;
  position: relative;
}
.marqueeCH div a {
    width: 100%;
    height: 100%;
    margin: 0;
    line-height: 50px;
    text-align: center;

    /* Starting position */
       -moz-transform:translateX(100%);
       -webkit-transform:translateX(100%);	
       transform:translateX(100%);

 /* Apply animation to this element */	
       -moz-animation: marqueeCH 15s linear infinite;
       -webkit-animation: marqueeCH 15s linear infinite;
       animation: marqueeCH 15s linear infinite;
}

/* Move it (define the animation) */
      @-moz-keyframes marqueeCH {
       0%   { -moz-transform: translateX(100%); }
       100% { -moz-transform: translateX(-100%); }
      }
      @-webkit-keyframes marqueeCH {
       0%   { -webkit-transform: translateX(100%); }
       100% { -webkit-transform: translateX(-100%); }
      }
      @keyframes marqueeCH {
       0%   { 
       -moz-transform: translateX(100%); /* Firefox bug fix */
       -webkit-transform: translateX(100%); /* Firefox bug fix */
       transform: translateX(100%); 		
       }
       100% { 
       -moz-transform: translateX(-100%); /* Firefox bug fix */
       -webkit-transform: translateX(-100%); /* Firefox bug fix */
       transform: translateX(-100%); 
       }
      }
@media only screen and (max-width:1200) {
  /* For tablets: */
  .responsive64{
    margin-top: 100px  !important;
    font-size: 24px  !important;
  }
  .responsive64s{
    font-size: 24px  !important;
  }

}
@media only screen and (max-width:1400px) {
  /* For Laptops: */
  .responsive64{
    margin-top: 0px  !important;
    font-size: 24px !important;
  }
  .responsive64s{
    font-size: 44px  !important;
  }
  .responsiveIFrame {
    width: 280px !important;
    height: 180px !important;
  } 
  .responsiveimageProf {
    width: 200px !important;
    height: 200px !important;
  } 
}

table, td, tr, tbody{

  border: #ffcb05 1px solid;
}

#scroll_news_H, #scroll_news_H h4{
  z-index: 0 !important;
}
.noIndex{
  z-index: 0 !important;
}

@keyframes App-logo-spin {
  
  from {
  transform: scale(0.8);
  
  }
  to {
    transform: scale(1.3);

  }
}
.nav-link{
  cursor: pointer;
  color: rgb(53, 52, 52) !important;

}
.navHover{
transition: 0.3s;
font-weight: 100 !important;
color: rgb(83, 83, 83) !important;
background-color:white !important;
}
.navHover:hover{
transition: 0.3s;
background-color: rgb(207, 207, 207) !important;
transform: scale(1.1);  
}
.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  padding: 2em;
  min-width: 20em;
  max-width: 70%;
  color: #f00;
  background-color: #fff;
  border-radius: 1em;
  transform: translate(-50%, -50%);
  outline: transparent;
}
.Overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,.4);
}
*{
  font-family: 'Cardo', serif , calibri;
}
